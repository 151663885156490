export const contentTableConfig = {
  isShowSelection: true,
  propList: [
    { prop: 'name', label: '姓名' },
    { prop: 'phone', label: '手机号' },
    { prop: 'remark', label: '备注' },
    { prop: 'create_time', label: '申请时间' },
    { prop: 'reject', label: '拒绝原因' }
  ]
}
